<template>
  <!-- Icons -->
  <div class="background">
    <Help></Help>
    <SmartSizeFooter></SmartSizeFooter>
  </div>
  <!-- Icons -->
</template>

<script>
import Help from "@/components/Help/Help.vue";
import SmartSizeFooter from "@/components/GlobalComponents/SmartSizeFooter.vue";

export default {
  data() {
    return {};
  },
  components: {
    Help,
    SmartSizeFooter,
  },
};
</script>

<style>
.background {
  /* background-color: rgb(244, 245, 244); */
  background-color: #f3f5f7;
  min-height: 100vh;
}
</style>