<template>
  <div class="icons-credit">
    <v-card class="transparent" flat>
      <!-- <v-img src="/smartsizetext.png"></v-img -->
      <v-card-actions>
        <v-btn 
          class="mx-4" 
          @click="goReviews" 
          color="black" 
          text
          v-if="showReview"
          >RATE SMARTSIZE</v-btn
        >
        <v-dialog
          v-model="dialog_enable"
          persistent
          min-heigth="1000"
          max-width="800"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="red"
              text
              v-bind="attrs"
              v-on="on"
              v-if="showUpgradeButton"
            >
              GET UNLIMITED SIZE CHARTS
            </v-btn>
          </template>
          <v-card class="pa-5">
            <v-row>
              <v-spacer></v-spacer
              ><v-icon @click="dialog_enable = false">mdi-close</v-icon>
            </v-row>
            <v-card-title class="mb-5 text-h5">
              Upgrade to Growth Plan for unlimited size charts
            </v-card-title>
            <v-card-subtitle class="text-h6 mb-2"
              >USD 6.99/month</v-card-subtitle
            >

            <v-card-text>
              <div class="font-weight-bold ml-8 mb-2">
                By upgrading to Growth, you will benefit from:
              </div>

              <v-timeline align-top large light fill-dot dense>
                <v-timeline-item
                  v-for="message in messages"
                  :key="message.time"
                  :color="message.color"
                  small
                >
                  <div>
                    <div class="font-weight-normal">
                      <strong>{{ message.from }}</strong> {{ message.time }}
                    </div>
                    <div>{{ message.message }}</div>
                  </div>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialog_enable = false"> Close </v-btn>
              <v-btn color="cyan" depressed dark @click="selectGrowthPlan()">
                ACTIVATE
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <a href="mailto:team@smartsize.io"
          ><v-btn color="black" text> SEND US AN EMAIL</v-btn></a
        >
        <v-btn
          color="black"
          text
          href="https://smartsize.zohodesk.eu/portal/en/kb"
          target="_blank"
        >
          VISIT OUR HELP CENTER</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-btn
      class="mx-10 mt-2"
      fab
      small
      dark
      color="cyan"
      href="https://smartsize.zohodesk.eu/portal/en/kb"
      target="_blank"
    >
      <v-icon dark> mdi-help </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog_enable: false,
      messages: [
        {
          from: "Unlimited Size Charts",
          message: `Create and publish unlimited size charts for your products`,
          color: "cyan",
        },
        {
          from: "Unrestricted displays",
          message:
            "No limitations into the amount in which the size charts are being displayed",
          color: "cyan",
        },
        {
          from: "Preferential support",
          message: "Get preferential support from our team",
          color: "cyan",
        },
        {
          from: "Customized images",
          message:
            "Ask for new illustrations and designs for your store, fully free",
          color: "cyan",
        },
      ],
    };
  },
  computed: {
    showUpgradeButton() {
      return (
        this.$store.getters.getShopProfile.plan_id == 2 || // Plan = Free
        this.$store.getters.getShopProfile.plan_id == 4 // Plan = Free Trial
      );
    },
    showReview() {
      return (
        !this.$store.getters.getShopProfile.has_rated_app // Has not yet rated app
      );
    },
  },
  methods: {
    goReviews() {
      this.$router.push({ name: "Reviews" });
    },
    async selectGrowthPlan() {
      let plan = {};
      await this.$http
        .get(process.env.VUE_APP_SERVER_URL + "/plan/setplan", {
          params: {
            shop: this.$store.getters.getShopProfile.shopname,
            id: 6, // 6 = Growth Plan 6.99
          },
        })
        .then((response) => (plan = response.data))
        .catch((error) => console.log(error));

      top.window.location = plan.redirect_url;
    },
  },
};
</script>

<style lang="scss" scoped>
.transparent {
  background-color: transparent !important;
  border-color: transparent !important;
}
.icons-credit {
  position: fixed;
  left: 0;
  height: 70px;
  display: flex;
  justify-content: flex-end;
  background: #f3f5f7;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  h5 {
    color: rgb(58, 53, 53);
    font-size: 12px;
  }
}
</style>